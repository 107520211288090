import PropTypes from "prop-types"
import React from "react"
import Image from "./core/Image"

export default function Hero({ image }) {
  return (
    <section className="container hero">
      <Image 
        className="hero__image" 
        data={image} 
        alt=""
        objectFit="contain"
      />
    </section>
  )
}

Hero.propTypes = {
  image: PropTypes.object.isRequired,
}
